import { LanguageStringsModel, LanguageTypeAll } from '@yuno/api/interface';

export const LanguagePipeWarning = 'No Language Provided!';
export const LanguagePipeMissing = (lang: string) => `Missing the "${lang}", language!`;

export const LanguagePipeFunction = (
	value: LanguageStringsModel | undefined,
	language?: string,
	fallBack?: string
): string => {
	if (!value) {
		return fallBack || '';
	}

	if (typeof value === 'string') {
		return value;
	}

	if (!language) {
		return LanguagePipeWarning;
	}

	if (value[language] === '' || value[language] === null) {
		return '';
	}

	if (value[language as LanguageTypeAll]) {
		return value[language] as string;
	}

	if (language === 'nl-BE') {
		console.warn(LanguagePipeMissing(language));
		if (value['nl'] as string) {
			return value['nl'] as string;
		}
	}

	return LanguagePipeMissing(language);
};

export const nunjuckLanguageFilterName = 'language';
export const nunjuckLanguageFilterFunction = (str: string, language: string) => {
	return LanguagePipeFunction(str, language);
};
